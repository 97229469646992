export async function getAsesores(id){
    return new Promise((resolve) => {
        var datos = [
            {nombre:'CI TALSA ANTIOQUIA', asesor:{nombre:"Edilson Rodriguez",correo:"edilson.rodriguez@talsa.com.co",telefono:"+57 3183122139"}},
            {nombre:'CI TALSA CENTRO', asesor:{nombre:"Oscar Filpe Reina",correo:"oscar.reina@talsa.com.co",telefono:"+57 3174394577"}},
            {nombre:'CI TALSA OCCIDENTE', asesor:{nombre:"Carlos Alberto Urrea",correo:"carlos.urrea@talsa.com.co",telefono:"+57 3167417092"}},
            {nombre:'CI TALSA COSTA', asesor:{nombre:"Pedro Pablo Cali",correo:"superbarranquilla@talsa.com.co",telefono:"+57 3173311389"}},
            {nombre:'CI TALSA EJE CAFETERO', asesor:{nombre:"Diego Montealegre",correo:"diego.montealegre@talsa.com.co",telefono:"+57 3174313001"}},
            {nombre:'CI TALSA SANTANDER', asesor:{nombre:"Omar Hernan Arcila",correo:"direccionsantander@talsa.com.co",telefono:"+57 3176373275"}},
            {nombre:'CI TALSA NORTE SANTANDER', asesor:{nombre:"Luis Alexander Santafe",correo:"cucuta@talsa.com.co",telefono:"+57 3183585509"}},
            {nombre:'SUPERMERCADO ANTIOQUIA', asesor:{nombre:"Alejandra Aguilar",correo:"alejandra.aguilar@talsa.com.co",telefono:"+57 3156203075"}},
            {nombre:'SUPERMERCADO CENTRO', asesor:{nombre:"Sandra Patricia Pedraza",correo:"patricia.pedraza@talsa.com.co",telefono:"+57 3042259740"}},
            {nombre:'SUPERMERCADO OCCIDENTE', asesor:{nombre:"Luz Elena Carvajal",correo:"luz.carvajal@talsa.com.co",telefono:"+57 3174394591"}},
            {nombre:'SUPERMERCADO COSTA', asesor:{nombre:"Pedro Pablo Cali Martinez",correo:"superbarranquilla@talsa.com.co",telefono:"+57 3173311389"}},
            {nombre:'SUPERMERCADO EJE CAFETERO', asesor:{nombre:"Habershon Josue Jiménez",correo:"habershon.jimenez@talsa.com.co",telefono:"+57 3183476180"}},
            {nombre:'SUPERMERCADO SANTANDER', asesor:{nombre:"Sandra Olarte",correo:"sandra.olarte@talsa.com.co",telefono:"+57 3173645669"}},
            {nombre:'SUPERMERCADO NORTE SANTANDER', asesor:{nombre:"Jorge Villamizar",correo:"jorge.villamizar@talsa.com.co",telefono:"+57 3176372321"}},
        ]
        var asesor = false;
        var url = id
        datos.forEach(element => {
            if(element.nombre == id){
                asesor = true;
                url = element.asesor
            }
        });

        if(asesor){
            resolve(url)
        }else{
            resolve(false)
        }
    })
}